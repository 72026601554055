import videoPosterMobile from '@/uikit/components/DistrictHeatLPHeroSection/assets/hannover-packts-an-hero-poster-mobile.webp';
import videoPoster from '@/uikit/components/DistrictHeatLPHeroSection/assets/hannover-packts-an-hero-poster.webp';
import Background from '../Background/Background';
import VideoPlayer from '../Video/VideoPlayer/VideoPlayer';
import styles from './DistrictHeatLPHeroSection.module.scss';
import { ReactComponent as LogoWithSlogan } from './assets/logo-with-slogan.svg';
import { ReactComponent as Motto } from './assets/motto.svg';
const videoData = {
  title: 'hannover-packts-an-hero',
  sizes: {
    '240p':
      '/cdn/videos/hannover-packts-an-hero_mobile/hannover-packts-an-hero_mobile_720.mp4', // vertical video
    '540p':
      '/cdn/videos/hannover-packts-an-hero_mobile/hannover-packts-an-hero_mobile_1080.mp4', // vertical video
    '720p':
      '/cdn/videos/hannover-packts-an-hero/hannover-packts-an-hero_720.mp4',
    '1080p':
      '/cdn/videos/hannover-packts-an-hero/hannover-packts-an-hero_1080.mp4',
  },
};

const DistrictHeatLPHeroSection = ({
  withLogo = false,
}: {
  withLogo?: boolean;
}) => {
  return (
    <section className={styles.base}>
      <Background.Image src={videoPoster.src} className={styles.poster} />
      <Background.Image
        src={videoPosterMobile.src}
        className={styles.posterMobile}
      />
      <VideoPlayer
        videoData={videoData}
        className={styles.video}
        loop
        muted
        autoPlay
        playsInline
      />

      {withLogo && (
        <div className={styles.logoWrapper}>
          <LogoWithSlogan className={styles.logo} />
        </div>
      )}

      <Motto className={styles.motto} />
    </section>
  );
};

export default DistrictHeatLPHeroSection;
